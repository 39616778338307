import React from "react";
import { Route, Switch } from "react-router-dom";

//Pages
import Home from "./pages/Home";
import ProjectPage from "./pages/ProjectPage";

// Global Styles
import GlobalStyle from "./components/GlobalStyle";

//Project Data
import projectsData from "./projectData";

function App() {
  const projects = projectsData();
  return (
    <div className="App">
      <GlobalStyle />
      <Switch>
        <Route exact path="/">
          <Home projects={projects} />
        </Route>
        <Route path="/project/:filterType">
          <ProjectPage projects={projects} />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
