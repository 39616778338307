import React, { useState } from "react";

import Nav from "../components/Nav";
import styled from "styled-components";

import { useParams } from "react-router-dom";

import { motion } from "framer-motion";

import { button } from "../animation";

const ProjectPage = ({ projects }) => {
  const { filterType } = useParams("");

  let project;

  for (const [index, value] of projects.entries()) {
    console.log(index, projects.length - 1, project);
    if (filterType === value.filter) {
      project = <ProjectSection key={index} project={value} />;
      break;
    } else {
      project = <h1>Project not found</h1>;
    }
  }

  return (
    <div>
      <Nav />
      <StyledMain>{project}</StyledMain>
    </div>
  );
};

const ProjectSection = ({ project }) => {
  const [buttonHover, setButtonHover] = useState(false);
  return (
    <StyledSection>
      <h1 className="title">{project.name}</h1>

      <p>{project.shortDescription}</p>
      <div className="github-visit">
        <a href={project.URL} rel="noopener noreferrer" target="_blank">
          <motion.div
            onMouseEnter={() => setButtonHover(true)}
            onMouseLeave={() => setButtonHover(false)}
            variants={button}
            animate={buttonHover ? "hover" : "noHover"}
            className="link-wrap"
          >
            <span className="site-link">VISIT THE SITE</span>
          </motion.div>
        </a>
        {project.github && (
          <a href={project.github} rel="noopener noreferrer" target="_blank">
            <div className="github-link">
              <p>GitHub Repo</p>
              <div className="icon">
                <svg
                  role="img"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="white"
                >
                  <title>GitHub</title>

                  <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
                </svg>
              </div>
            </div>
          </a>
        )}
      </div>
      <div className="project-image">
        <img src={project.image} alt={project.name} />
      </div>

      <div className="technologies">
        <h2>Key Technologies</h2>
        <p>
          Web Development technologies that were used to build this project.
        </p>
        <ul>
          {project.technologies.map((skill, index) => {
            return <li key={index}>{skill}</li>;
          })}
        </ul>
      </div>
    </StyledSection>
  );
};

export default ProjectPage;

const StyledMain = styled.main`
  width: 1050px;
  margin: 0 auto 10rem auto;
  color: white;

  @media (max-width: 1050px) {
    width: 95%;
    margin: 0 2.5%;
  }
`;

const StyledSection = styled.section`
  .title {
    margin: 4rem 0 2rem 0;
    font-size: 3rem;
    font-weight: 700;
    @media (max-width: 700px) {
      font-size: 2.4rem;
    }

    a {
      font-size: 1.8rem;
      margin-left: 1rem;
      color: white;
      :hover {
        text-decoration: underline;
      }
    }
  }

  p {
    font-size: 1.6rem;
    color: #a09f9f;
  }
  .technologies {
    margin: 5rem 0 2rem 0;
    h2 {
      margin: 2rem 0;
      font-size: 3rem;
      @media (max-width: 700px) {
        font-size: 2.4rem;
      }
    }
    ul {
      margin: 1rem 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      list-style: none;
      padding-left: 0;
      font-size: 2.2rem;

      li {
        padding: 0.8rem 0.5rem;
        background-color: #313131;
        margin: 1rem 4rem 1rem 0;
        border-color: #222;
        border-style: solid;
        font-size: 2rem;
        border-width: 1px 1px 2px 1px;
        color: #999;
        @media (max-width: 700px) {
          font-size: 1.8rem;
          margin: 1rem 2rem 1rem 0;
        }
      }
    }
  }

  .github-visit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0 0.5rem 0;
    .github-link {
      display: flex;
      justify-content: center;
      align-items: center;

      :hover {
        svg {
          fill: #30d5c8;
        }
      }
      p {
        font-size: 1.8rem;
      }
      .icon {
        width: 3.2rem;
        height: 3.2rem;
        margin: 0 0.8rem;

        svg {
          transition: fill 0.3s ease-out;
        }
      }
    }
  }
  .link-wrap {
    display: inline-block;
    height: 38px;
    box-shadow: 0 4px 0 #005f56;
    background-color: #00877a;
    padding: 1rem;
    border-radius: 0.2rem;
  }
  a {
    :hover {
      text-decoration: none;
    }
  }
  .site-link {
    display: block;
    position: relative;
    width: fit-content;
    color: White;
    font-size: 1.4rem;
    font-weight: 600;
    text-decoration: none;
  }
  .project-image {
    margin: 2rem 0;
    img {
      box-shadow: 6px 6px 0 rgb(0 0 0 / 15%);
      border: 1px solid #dddddd;
      width: 100%;
    }
  }
`;
