import React from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <StyledNav>
      <div className="home-icon">
        <Link to="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60
            "
            fill="#ffffff"
            height="60"
            viewBox="0 0 100 100"
          >
            <rect width="100" height="100" rx="50"></rect>
            <path
              fill="#000000"
              d="M43.91 66.05L43.91 50L48.74 50L48.74 68.16Q45.41 71.08 40.85 72.64Q36.30 74.21 31.27 74.21L31.27 74.21Q24.13 74.21 18.41 71.08Q12.70 67.95 9.44 62.41Q6.17 56.87 6.17 50L6.17 50Q6.17 43.13 9.44 37.59Q12.70 32.05 18.41 28.92Q24.13 25.79 31.33 25.79L31.33 25.79Q36.77 25.79 41.33 27.53Q45.89 29.26 49.01 32.66L49.01 32.66L45.89 35.86Q40.24 30.28 31.54 30.28L31.54 30.28Q25.76 30.28 21.10 32.83Q16.44 35.38 13.79 39.90Q11.14 44.42 11.14 50L11.14 50Q11.14 55.58 13.79 60.06Q16.44 64.55 21.07 67.14Q25.69 69.72 31.47 69.72L31.47 69.72Q38.81 69.72 43.91 66.05L43.91 66.05ZM76.08 74.21Q70.77 74.21 65.91 72.51Q61.05 70.81 58.40 68.02L58.40 68.02L60.37 64.14Q62.95 66.73 67.24 68.33Q71.52 69.92 76.08 69.92L76.08 69.92Q82.47 69.92 85.67 67.58Q88.86 65.23 88.86 61.49L88.86 61.49Q88.86 58.64 87.13 56.94Q85.39 55.24 82.88 54.32Q80.36 53.40 75.87 52.31L75.87 52.31Q70.50 50.95 67.31 49.69Q64.11 48.44 61.83 45.85Q59.55 43.27 59.55 38.85L59.55 38.85Q59.55 35.24 61.46 32.29Q63.36 29.33 67.31 27.56Q71.25 25.79 77.10 25.79L77.10 25.79Q81.18 25.79 85.09 26.91Q89.00 28.04 91.85 30.01L91.85 30.01L90.15 34.02Q87.16 32.05 83.76 31.06Q80.36 30.08 77.10 30.08L77.10 30.08Q70.84 30.08 67.68 32.49Q64.52 34.90 64.52 38.71L64.52 38.71Q64.52 41.57 66.25 43.30Q67.99 45.04 70.60 45.95Q73.22 46.87 77.64 47.96L77.64 47.96Q82.88 49.25 86.11 50.51Q89.34 51.77 91.58 54.32Q93.83 56.87 93.83 61.22L93.83 61.22Q93.83 64.82 91.89 67.78Q89.95 70.74 85.94 72.47Q81.93 74.21 76.08 74.21L76.08 74.21Z"
            ></path>
          </svg>
        </Link>
      </div>

      <div className="nav-links">
        <div className="icons-wrap">
          <div className="icon">
            <a
              href="https://github.com/georgeosutton"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fill="white"
              >
                <title>GitHub</title>

                <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
              </svg>
            </a>
          </div>
          <div className="icon">
            <a
              href="https://www.linkedin.com/in/george-sutton-b7a106153/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fill="white"
              >
                <title>LinkedIn</title>
                <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </StyledNav>
  );
};

const StyledNav = styled.nav`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  min-height: 10vh;
  padding: 1rem 0rem;
  background-color: #222222;
  color: white;

  .home-icon {
    margin: 0 4rem;
    svg {
      transition: fill 0.3s ease-out;
      :hover {
        fill: #30d5c8;
      }
    }
  }
  a {
    color: white;
  }

  .nav-links {
    margin: 0 4%;
    display: flex;

    ul {
      display: flex;
      list-style: none;
      justify-content: center;
      align-items: center;
      width: fit-content;
      margin: 0 1rem;
      li {
        margin: 0 1rem;
        font-size: 1.6rem;
      }

      @media (max-width: 700px) {
        li {
          margin: 0 0.6rem;
        }
      }
    }
  }
  .icons-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      width: 3.2rem;
      height: 3.2rem;
      margin: 0 0.8rem;

      svg {
        transition: fill 0.3s ease-out;
        :hover {
          fill: #30d5c8;
        }
      }
    }
  }

  img:hover {
    opacity: 0.7;
  }
`;
export default Nav;
