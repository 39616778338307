export const fadeIn = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      ease: "easeIn",
      duration: 0.75,
    },

    exit: {
      opacity: 0,
      transition: {
        duration: 0.75,
      },
    },
  },
};

export const button = {
  hover: {
    translateY: 1,
    boxShadow: "0 2px 0 rgb(0, 95, 86, 1)",
    transition: {
      duration: 0.2,
    },
  },

  noHover: {
    boxShadow: "0 4px 0 rgb(0, 95, 86, 1)",
    transition: {
      duration: 0.2,
    },
  },
};

export const card = {
  hidden: {
    scale: 0.9,
  },
  show: {
    scale: 1,
    transition: {
      ease: "easeOut",
      duration: 0.5,
    },
  },
  hover: {
    translateX: 0.5,
    translateY: 0.5,
    transition: {
      duration: 0,
    },
  },
};

export const pageAnimation = {
  hidden: {
    opacity: 0,
    y: -300,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      staggerChildren: 0.3,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

export const titleAnimation = {
  hidden: { y: 50 },
  show: {
    y: 0,
    transition: { duration: 0.5, ease: "easeOut" },
  },
};
