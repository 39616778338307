import React, { useState } from "react";
import styled from "styled-components";

import { motion } from "framer-motion";
import { card } from "../animation";
import { titleAnimation } from "../animation";
import { Link } from "react-router-dom";

const Project = ({ project }) => {
  const [isHover, setIsHover] = useState(false);
  const { technologies, image, name } = project;
  return (
    <StyledProject
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      variants={card}
      whileHover="hover"
    >
      <Link to={`/project/${project.filter}`}>
        <h2>{name}</h2>
        <Overview
          technologies={technologies}
          image={image}
          isHover={isHover}
          name={name}
        />
      </Link>
    </StyledProject>
  );
};

const Overview = ({ technologies, image, isHover, name }) => {
  return (
    <StyledOverview>
      <motion.div className="info">
        <ul>
          {technologies.map((skill, index) => {
            return (
              <li key={index}>
                <motion.p
                  variants={titleAnimation}
                  initial="hidden"
                  animate={isHover ? "show" : ""}
                >
                  {skill}
                </motion.p>
              </li>
            );
          })}
        </ul>
      </motion.div>

      <img className="project-img" src={image} alt={name} />
    </StyledOverview>
  );
};

const StyledProject = styled(motion.div)`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  background-color: #dddddd;
  box-shadow: 6px 6px 0 rgb(0 0 0 / 15%);

  :hover {
    img {
      filter: brightness(40%);
    }
  }
  a:hover {
    text-decoration: none;
  }
  h2 {
    font-family: "Montserrat", sans-serif;
    margin: 1.2rem 0;
    text-align: center;
    font-size: 2rem;
    color: #202020;
  }
`;

const StyledOverview = styled(motion.div)`
  height: 400px;
  position: relative;

  @media (max-width: 600px) {
    height: 300px;
  }
  @media (max-width: 400px) {
    height: 250px;
  }

  .info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);

    ul {
      margin: 0 3rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      list-style: none;
      width: fit-content;

      li {
        overflow: hidden;
        margin: 0.5rem;
        list-style: none;
      }
    }
    p {
      font-size: 1.6rem;
      background-color: #2a2a2a;
      box-shadow: 1px 1px 0 rgb(0 0 0 / 25%);
      border-radius: 0.2rem;
      color: white;
      padding: 0.5rem;
    }
  }

  .project-img {
    transition: 0.2s ease;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;

export default Project;
