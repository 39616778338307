import React from "react";
import Project from "../components/Project";
import Nav from "../components/Nav";
import styled from "styled-components";

const Home = ({ projects }) => {
  return (
    <HomeStyled>
      <Nav />
      <main>
        <div className="title-wrap">
          <h1 className="projects-title">Web Developer Portfolio</h1>
        </div>
        <StyledProjects>
          {projects.map((project, index) => (
            <Project key={index} project={project} />
          ))}
        </StyledProjects>
      </main>
    </HomeStyled>
  );
};

const StyledProjects = styled.div`
  padding: 0 0 3rem 0;
  display: grid;
  grid-template-columns: repeat(2, 500px);
  grid-column-gap: 5rem;
  grid-row-gap: 5rem;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;

  @media (max-width: 1100px) {
    grid-template-columns: 500px;
    grid-column-gap: 0rem;
    margin: 0 5%;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-column-gap: 0rem;
    margin: 0 5%;
  }
`;

const HomeStyled = styled.div`
  .title-wrap {
    overflow: hidden;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 500px);
    grid-column-gap: 5rem;
    grid-row-gap: 5rem;
    @media (max-width: 1100px) {
      grid-template-columns: 500px;
      grid-column-gap: 0rem;
      margin: 0 5%;
    }
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
      grid-column-gap: 0rem;
      margin: 0 5%;
    }
    h1 {
      color: white;
      margin: 4rem 0;
      font-size: 3rem;
      @media (max-width: 600px) {
        margin: 2.5rem 0;
        font-size: 2.6rem;
      }
    }
  }
  .projects-title {
    font-size: 2.4rem;
    overflow: hidden;
  }
`;

export default Home;
