import captureImg from "./img/capture-img.png";
import musicImg from "./img/music-img.png";

import ignite from "./img/ignite.png";
import precious from "./img/precious.png";
import movieDB from "./img/movieDB.png";
import tch from "./img/tch.png";
import jwcc from "./img/jwcc.png";

function projectsData() {
  return [
    {
      name: "The Campaign House",
      image: tch,
      shortDescription:
        "I built the corporate website for the campaign house independently, working from the project design spec. After launch I Integrating the static version of the website with a Headless CMS",
      filter: "the-campaign-house",
      URL: "https://thecampaignhouse.co.uk/",
      github: false,
      technologies: [
        "Next.js",
        "React",
        "HTML5",
        "SCSS",
        "Javascript ES6",
        "Headless CMS",
        "Sanity.io",
      ],
    },
    {
      name: "John White Community Centre",
      image: jwcc,
      shortDescription:
        "I volunteered my services to John White Community centre. The website is built with Next.js and Sanity.io. This is a project I continue to work on and improve.",
      filter: "john-white-community-centre",
      URL: "https://thejohnwhitecommunitycentre.co.uk/",
      github: false,
      technologies: [
        "Next.js",
        "React",
        "HTML5",
        "SCSS",
        "Javascript ES6",
        "Headless CMS",
        "Sanity.io",
      ],
    },
    {
      name: "Precious Creations Shop",
      image: precious,
      shortDescription:
        "Precious Creations is an ecommerce site built using React, Redux and Firebase. Developing this site gave me invaluable experience in building a larger application from the ground up. The site includes various features expected of a ecommerce platform such as a content management system and user login functionality.",
      filter: "precious-creations",
      URL: "https://precious-creations.uk/",
      github: false,
      technologies: [
        "React",
        "HTML5",
        "SCSS",
        "Firebase",
        "Redux",
        "Javascript ES6",
        "API",
        "Stripe",
        "PayPal",
        "ExpressJS",
      ],
    },
    {
      name: "Ignite Game Search",
      image: ignite,
      shortDescription:
        "Utilising the use of a public API this site displays information about the latest games.",
      filter: "ignite",
      URL: "https://ignite-api.netlify.app/",
      github:
        "https://github.com/georgeosutton/Ignite-API-Files/tree/main/Ignite",
      technologies: [
        "React",
        "Redux",
        "API",
        "HTML5",
        "Javascript ES6",
        "Styled Components",
        "Animations",
      ],
    },
    {
      name: "Music Player",
      image: musicImg,
      shortDescription: "This website plays a list of set music tracks.",
      filter: "music-player",
      URL: "https://music-project.netlify.app/",
      github:
        "https://github.com/georgeosutton/Music-Player-Files/tree/main/react-player",
      technologies: ["React", "SCSS", "HTML5", "Javascript ES6", "Audio"],
    },
    {
      name: "Movie DB ",
      image: movieDB,
      shortDescription:
        "A website built with Vue which displays the latest movies utilising an API. The site also provides a details page for each movie.",
      filter: "movieDB",
      URL: "https://movie-api-db.netlify.app/",
      github: "https://github.com/georgeosutton/movie-db",
      technologies: ["Vue", "HTML5", "Javascript ES6", "API", "SCSS"],
    },
    {
      name: "Capture Portfolio ",
      image: captureImg,
      shortDescription:
        "This multi page site was built using React and demostrates my Front-End capabilities.",
      filter: "capture-portfolio",
      URL: "https://capture-portfolio.netlify.app/",
      github:
        "https://github.com/georgeosutton/Capture-Portfolio-Files/tree/main/capture",
      technologies: [
        "React",
        "HTML5",
        "Javascript ES6",
        "Animations",
        "Styled Components",
      ],
    },
  ];
}

export default projectsData;
