import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
*{
    margin:0;
    padding:0;
    box-sizing:border-box;
}


body{
   font-family: "Montserrat", sans-serif;
   background-color: #2a2a2a; 
}
p{
   
    line-height:2.4rem;
}

html{
    font-size:62.5%;
    display:block;
}

a{
    text-decoration:none;
    color:black;

}
a:hover{
        text-decoration:underline;
    }

`;

export default GlobalStyle;
